main.contact-main {
    padding-left: 16px;
    padding-right: 16px;
}

.contact-grid {
    display: grid;
    row-gap: 5px;
    column-gap: 2%;
    margin-top: 15px;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 48%);
}

.contact-section {
    border-radius: 5px;
    background-color: #fdfdfd;
    align-items: center;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    aspect-ratio: 2/1;
}

p.contact-text {
    margin: 16px;
    overflow:scroll;
    /* font-size: 2.1vw; */
}

.section-title {
    margin-bottom: .4em;
}