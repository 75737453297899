main.about-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.about-main > p {
    text-align: center;
}

.skill-list {
    list-style: none;
}

.skill-list > li {
    text-align: center;
    font-size: 24px;
    margin-right: 60px;
}

.skill-list > li::before {
    content: "> ";
    color: greenyellow;
}

.about-img {
    display:flex;
    margin-top: 0;
    width: 100vw;
    margin-bottom: 20px;
    object-fit: none;
    max-height: 400px;
}