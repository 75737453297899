/* In components/styles/Navbar.css */

.navbar {
    background-color: #4A4E69; /* Dark background for the navbar */
    height: 65px; /* Adjust based on your preference */
    display: flex;
    justify-content: space-between; /* Align items to the ends */
    align-items: center;
    font-size: 1.2rem;
    position: sticky; /* Make the navbar stick to the top */
    top: 0;
    z-index: 100; /* Ensure navbar is above other elements */
    padding: 0 20px; /* Padding on the sides */
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between; /* Align logo and menu icon to the ends */
    align-items: center;
    width: 100%;
  }
  
  .navbar-logo {
    color: #F2E9E4;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    transition: transform 0.5s ease; /* Smooth transition for logo */
  }
  
  .navbar-logo:hover {
    transform: scale(1.1); /* Slightly enlarge the logo on hover */
  }
  
  .navbar-logo img {
    margin-right: 0.5rem; /* Small space between logo and text */
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  
  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-out; /* Smooth transition for menu */
  }
  
  .nav-item {
    margin-right: 1rem;
  }
  
  .nav-links {
    color: #F2E9E4;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease-out, background-color 0.3s ease-out; /* Smooth transition for links */
  }
  
  .nav-links:hover {
    color: #4A4E69; /* Change text color on hover */
    background-color: #F2E9E4; /* Change background color on hover */
    border-radius: 4px;
  }
  
  /* Responsive design for small screens */
  @media screen and (max-width: 960px) {
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-50%, 60%);
      font-size: 1.8rem;
      color: #F2E9E4;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh; /* Full screen height */
      position: absolute;
      top: 65px; /* Height of the navbar */
      left: -100%;
      opacity: 0; /* Hidden by default */
      transition: all 0.5s ease; /* Smooth transition for menu */
      margin-top: 0;
      background: #4A4E69;
    }
  
    .nav-menu.active {
        padding: 0;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        margin-top: 0;
    }
  
    .nav-item {
      width: 100%;
      margin: 0; /* Reset margin for mobile view */
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table; /* Center links */
    }

    .nav-links.active {
        padding-left: 0;
    }
  
    .nav-links:hover {
      background-color: #ffffff;
      color: #4A4E69;
      border-radius: 0;
    }
  }
  